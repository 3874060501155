import React from 'react'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields, TipoVia } from '../../consts'

export default function NombreVia({ title, label, required = true }) {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = () => {
    const fields = getValues()
    const streetType = document.getElementById('streetType')
    const streetTypeValue = streetType.options[streetType.selectedIndex].text
    const address = [streetTypeValue, fields.address]
    setValue('MCCPERSTAG.TOMADOR.NombreVia', address.join(' '))
    setStorageData(getValues())
  }

  return (
    <div className="form-field row">
      {title && <span>{title}</span>}
      <input
        type="hidden"
        id="MCCPERSTAG.TOMADOR.NombreVia"
        {...register('MCCPERSTAG.TOMADOR.NombreVia', {
          onChange: handleChange,
          required: required === true ? 'La dirección es obligatoria.' : false,
        })}
      />

      <div className="form-group col-md-4">
        <label htmlFor="streetType">{label || 'Tipo de vía'}</label>
        <select
          id="streetType"
          className={`form-control form-select${
            errors.street_type ? ' hasError' : ''
          }`}
          defaultValue={''}
          {...register('streetType', {
            onChange: handleChange,
            required:
              required === true ? 'El tipo de calle es obligatorio.' : false,
            pattern: {
              value: /[A-Z]{2}/,
              message: 'Has introducido caracteres no permitidos.',
            },
            maxLength: {
              value: 2,
              message: 'El tipo de calle es demasiado largo.',
            },
          })}
        >
          <option value="">Tipo</option>
          {TipoVia.map((tipo, index) => {
            return (
              <option
                key={index}
                value={tipo.cod}
              >
                {tipo.name}
              </option>
            )
          })}
        </select>
      </div>

      <div className="form-group col-md-8">
        <label htmlFor="address">{label || 'Dirección'}</label>
        <input
          className={`form-control${errors?.address ? ' hasError' : ''}`}
          type="text"
          id="address"
          placeholder={label || 'Dirección'}
          {...register('address', {
            onChange: handleChange,
            required:
              required === true ? 'La dirección es obligatoria.' : false,
            pattern: {
              value: /^[a-zA-ZÀ-ÖØ-öø-ÿ 0-9º º/\\.,-]*$/i,
              message: 'Has introducido caracteres no permitidos.',
            },
            minLength: {
              value: 5,
              message: 'La dirección es demasiado corta.',
            },
            maxLength: {
              value: 150,
              message: 'La dirección es demasiado larga.',
            },
          })}
        />
      </div>

      {(errors?.streetType || errors?.address) && (
        <div className="error">
          <p>{errors.streetType && errors.streetType.message}</p>
          <p>{errors.address && errors.address.message}</p>
        </div>
      )}
    </div>
  )
}
