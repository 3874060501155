import React from 'react'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields } from '../../consts'

export default function Localidad({ title, label, required = true }) {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext()

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = () => {
    setStorageData(getValues())
  }

  return (
    <div className="form-field">
      {title && <span>{title}</span>}
      <div className="form-group">
        <label htmlFor="MCCPERSTAG.TOMADOR.Localidad">
          {label || 'Localidad'}
        </label>
        <input
          className={`form-control${
            errors.MCCPERSTAG?.TOMADOR?.Localidad ? ' hasError' : ''
          }`}
          type="text"
          id="WOO.billing_city"
          placeholder={label || 'Localidad'}
          {...register('MCCPERSTAG.TOMADOR.Localidad', {
            onChange: handleChange,
            required:
              required === true ? 'La localidad es obligatoria.' : false,
            pattern: {
              value: /^[a-zA-ZÀ-ÖØ-öø-ÿ -()"]+$/i,
              message: 'Has introducido caracteres no permitidos.',
            },
            minLength: {
              value: 3,
              message: 'La localidad es demasiado corta.',
            },
            maxLength: {
              value: 50,
              message: 'La localidad es demasiado larga.',
            },
          })}
        />

        {errors.MCCPERSTAG?.TOMADOR?.Localidad && (
          <div className="error">
            {errors.MCCPERSTAG.TOMADOR.Localidad.message}
          </div>
        )}
      </div>
    </div>
  )
}
