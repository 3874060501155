import { useEffect } from 'react'
import useSessionStorage from './useSessionStorage'
import useAppNavigation from './useAppNavigation'

export function useWooCart() {
  const [cart, setCart] = useSessionStorage('woo_cart', null)
  const { currentStep } = useAppNavigation()

  const getCart = async (data) => {
    if (data?.action === undefined) data = { ...data, action: 'get_woo_cart' }

    const response = await fetch(
      `${window.location.protocol}//${window.location.host}/wp-admin/admin-ajax.php`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(data).toString(),
      }
    )
    if (!response.ok) {
      throw new Error('Failed to fetch woo cart.')
    }

    const json = await response.json()
    setCart(json)
    return json
  }

  const addToCart = async (data) => {
    console.log('addToCart')
    const obj = {
      MCCRETARITAG: JSON.stringify(
        Object.keys(data.MCCRETARITAG)
          .filter((k) => data.MCCRETARITAG[k] != null)
          .reduce((a, k) => ({ ...a, [k]: data.MCCRETARITAG[k] }), {})
      ),
      MCCPERSTAG: JSON.stringify(
        Object.keys(data.MCCPERSTAG)
          .filter((k) => data.MCCPERSTAG[k] != null)
          .reduce((a, k) => ({ ...a, [k]: data.MCCPERSTAG[k] }), {})
      ),
      WOO: JSON.stringify(
        Object.keys(data.WOO)
          .filter((k) => data.WOO[k] != null)
          .reduce((a, k) => ({ ...a, [k]: data.WOO[k] }), {})
      ),
    }

    getCart({
      ...obj,
      action: 'update_woo_cart',
      product_id: currentStep.id_product,
    }).then((response) => {
      setCart(response)
      console.log(response)
    })
  }

  useEffect(() => {
    if (cart === null) {
      getCart().then((response) => setCart(response))
    }
  }, [])

  return { cart, getCart, addToCart }
}
