import React, { useEffect, useState } from 'react'
import useAppNavigation from '../../hooks/useAppNavigation'
import './WooProducts.css'

export default function WooProducts({ wooProducts }) {
  const { currentStep } = useAppNavigation()
  const [wooProduct, setWooProduct] = useState(null)

  useEffect(() => {
    document.getElementById(`block_faqs`).classList.remove('d-none')

    if (currentStep.id_product && wooProducts) {
      setWooProduct(
        wooProducts
          .filter((product) => product.id_product === currentStep.id_product)
          .shift()
      )
    }
    // console.log('useEffect')
  }, [currentStep.id_product, wooProducts])

  return (
    <>
      {wooProduct && (
        <div id="woo-product">
          <div className="h3">{wooProduct.title}</div>
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: wooProduct.content,
            }}
          ></div>
        </div>
      )}
    </>
  )
}
