export const initialFields = {
  MCCRETARITAG: {
    NumPropuesta: null,
    CodModalidad: null,
    FecNacimiento: null,
    CodRaza: null,
    Raza: null,
    Cruzado: null,
    CodRazaCruzado: null,
    RazaCruzado: null,
    Sexo: null,
    ValorAnimal: null,
    Nombre: null,
    Microchip: null,
    Vacunas: null,
    Enfermedad: null,
    Destino: null,
    CodBanco: null,
    CodSucursal: null,
    Digito: null,
    Cuenta: null,
    CompaniaAnterior: null,
    DetalleGarantias: null,
    RefAuxiliar: null,
    Cias: null,
  },
  MCCPERSTAG: {
    TOMADOR: {
      TipoPersona: 'TO',
      OrdenPersona: '1',
      Nombre: null,
      Apellido1: null,
      Apellido2: null,
      Nif: null,
      TelefonoParticular: null,
      Email: null,
      FechaNacimiento: null,
      CodPostal: null,
      Localidad: null,
      NombreVia: null,
      NumVia: null,
    },
  },
  WOO: {},
}

export const initialCart = {
  currentStep: null,
}

export const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]

export const provincias = {
  C: 'La Coruña',
  VI: 'Álava',
  AB: 'Albacete',
  A: 'Alicante',
  AL: 'Almería',
  O: 'Asturias',
  AV: 'Ávila',
  BA: 'Badajoz',
  PM: 'Baleares',
  B: 'Barcelona',
  BU: 'Burgos',
  CC: 'Cáceres',
  CA: 'Cádiz',
  S: 'Cantabria',
  CS: 'Castellón',
  CE: 'Ceuta',
  CR: 'Ciudad Real',
  CO: 'Córdoba',
  CU: 'Cuenca',
  GI: 'Gerona',
  GR: 'Granada',
  GU: 'Guadalajara',
  SS: 'Guipúzcoa',
  H: 'Huelva',
  HU: 'Huesca',
  J: 'Jaén',
  LO: 'La Rioja',
  GC: 'Las Palmas',
  LE: 'León',
  L: 'Lérida',
  LU: 'Lugo',
  M: 'Madrid',
  MA: 'Málaga',
  ML: 'Melilla',
  MU: 'Murcia',
  NA: 'Navarra',
  OR: 'Orense',
  P: 'Palencia',
  PO: 'Pontevedra',
  SA: 'Salamanca',
  TF: 'Santa Cruz de Tenerife',
  SG: 'Segovia',
  SE: 'Sevilla',
  SO: 'Soria',
  T: 'Tarragona',
  TE: 'Teruel',
  TO: 'Toledo',
  V: 'Valencia',
  VA: 'Valladolid',
  BI: 'Vizcaya',
  ZA: 'Zamora',
  Z: 'Zaragoza',
}

export const PostalCodeProvincias = [
  { CPRO: '15', woo: 'C', name: 'La Coruña' },
  { CPRO: '01', woo: 'VI', name: 'Álava' },
  { CPRO: '02', woo: 'AB', name: 'Albacete' },
  { CPRO: '03', woo: 'A', name: 'Alicante' },
  { CPRO: '04', woo: 'AL', name: 'Almería' },
  { CPRO: '33', woo: 'O', name: 'Asturias' },
  { CPRO: '05', woo: 'AV', name: 'Ávila' },
  { CPRO: '06', woo: 'BA', name: 'Badajoz' },
  { CPRO: '07', woo: 'PM', name: 'Baleares' },
  { CPRO: '08', woo: 'B', name: 'Barcelona' },
  { CPRO: '09', woo: 'BU', name: 'Burgos' },
  { CPRO: '10', woo: 'CC', name: 'Cáceres' },
  { CPRO: '11', woo: 'CA', name: 'Cádiz' },
  { CPRO: '39', woo: 'S', name: 'Cantabria' },
  { CPRO: '12', woo: 'CS', name: 'Castellón' },
  { CPRO: '51', woo: 'CE', name: 'Ceuta' },
  { CPRO: '13', woo: 'CR', name: 'Ciudad Real' },
  { CPRO: '14', woo: 'CO', name: 'Córdoba' },
  { CPRO: '16', woo: 'CU', name: 'Cuenca' },
  { CPRO: '17', woo: 'GI', name: 'Gerona' },
  { CPRO: '18', woo: 'GR', name: 'Granada' },
  { CPRO: '19', woo: 'GU', name: 'Guadalajara' },
  { CPRO: '19', woo: 'SS', name: 'Guipúzcoa' },
  { CPRO: '21', woo: 'H', name: 'Huelva' },
  { CPRO: '22', woo: 'HU', name: 'Huesca' },
  { CPRO: '23', woo: 'J', name: 'Jaén' },
  { CPRO: '26', woo: 'LO', name: 'La Rioja' },
  { CPRO: '35', woo: 'GC', name: 'Las Palmas' },
  { CPRO: '24', woo: 'LE', name: 'León' },
  { CPRO: '25', woo: 'L', name: 'Lérida' },
  { CPRO: '27', woo: 'LU', name: 'Lugo' },
  { CPRO: '28', woo: 'M', name: 'Madrid' },
  { CPRO: '29', woo: 'MA', name: 'Málaga' },
  { CPRO: '52', woo: 'ML', name: 'Melilla' },
  { CPRO: '30', woo: 'MU', name: 'Murcia' },
  { CPRO: '31', woo: 'NA', name: 'Navarra' },
  { CPRO: '32', woo: 'OR', name: 'Orense' },
  { CPRO: '34', woo: 'P', name: 'Palencia' },
  { CPRO: '36', woo: 'PO', name: 'Pontevedra' },
  { CPRO: '37', woo: 'SA', name: 'Salamanca' },
  { CPRO: '38', woo: 'TF', name: 'Santa Cruz de Tenerife' },
  { CPRO: '40', woo: 'SG', name: 'Segovia' },
  { CPRO: '41', woo: 'SE', name: 'Sevilla' },
  { CPRO: '42', woo: 'SO', name: 'Soria' },
  { CPRO: '43', woo: 'T', name: 'Tarragona' },
  { CPRO: '44', woo: 'TE', name: 'Teruel' },
  { CPRO: '45', woo: 'TO', name: 'Toledo' },
  { CPRO: '46', woo: 'V', name: 'Valencia' },
  { CPRO: '47', woo: 'VA', name: 'Valladolid' },
  { CPRO: '48', woo: 'BI', name: 'Vizcaya' },
  { CPRO: '49', woo: 'ZA', name: 'Zamora' },
  { CPRO: '50', woo: 'Z', name: 'Zaragoza' },
]

export const TipoVia = [
  {
    name: 'Calle',
    cod: 'CL',
  },
  {
    name: 'Avenida',
    cod: 'AV',
  },
  {
    name: 'Paseo',
    cod: 'PS',
  },
  {
    name: 'Carretera',
    cod: 'CR',
  },
  {
    name: 'Parque',
    cod: 'PQ',
  },
  {
    name: 'Polígono',
    cod: 'PO',
  },
  {
    name: 'Travesía',
    cod: 'TR',
  },
  {
    name: 'Urbanización',
    cod: 'UR',
  },
  {
    name: 'Otro',
    cod: 'OT',
  },
]
