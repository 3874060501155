import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from './useSessionStorage'

export default function useAppNavigation() {
  const navigate = useNavigate()
  const { step } = useParams()
  const { trigger, getValues } = useFormContext()
  const [wooProduct, setWooProduct] = useState(null)

  const stepURL = steps
    .filter((route) => {
      return route.url === step
    })
    .shift()

  const [currentStep, setCurrentStep] = useSessionStorage('currentStep', {
    ...stepURL,
    id_product: wooProduct,
  })

  const handlePrev = () => {
    const prevStep =
      currentStep.id > 0
        ? {
            ...steps[currentStep.id - 1],
            id_product: getValues('dangerousBreed') === 'false' ? 20 : 133,
          }
        : currentStep

    setCurrentStep(prevStep)
    navigate('/flow/' + prevStep.url)
    // console.log('handlePrev')
  }

  const handleNext = () => {
    let nextStep =
      currentStep.id < Object.keys(steps).length - 1
        ? {
            ...steps[currentStep.id + 1],
            id_product: getValues('dangerousBreed') === 'false' ? 20 : 133,
          }
        : currentStep

    trigger().then((response) => {
      nextStep = { ...nextStep, hasError: !response }
      if (nextStep.hasError === false) {
        setCurrentStep(nextStep)
        navigate('/flow/' + nextStep.url)
      }
    })
    // console.log('handleNext')
  }

  useEffect(() => {
    if (stepURL === undefined) {
      navigate('/flow/paso-1')
    }
    setCurrentStep(
      stepURL
        ? {
            ...stepURL,
            id_product: getValues('dangerousBreed') === 'false' ? 20 : 133,
          }
        : {
            ...steps[0],
            id_product: getValues('dangerousBreed') === 'false' ? 20 : 133,
          }
    )
    // console.log('useEffect', currentStep)
  }, [step, getValues('dangerousBreed')])

  return { steps, currentStep, handlePrev, handleNext }
}

const steps = [
  {
    id: 0,
    url: 'paso-1',
  },
  {
    id: 1,
    url: 'paso-2',
  },
  {
    id: 2,
    url: 'paso-3',
  },
  {
    id: 3,
    url: 'paso-4',
  },
]
