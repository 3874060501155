import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useWooCart } from '../../hooks/useWooCart'
import useAppNavigation from '../../hooks/useAppNavigation'
import NavButtons from './NavButtons'
import { provincias } from '../../consts'
import './CartResume.css'

export default function CartResume({ wooProducts }) {
  const [cart, setCart] = useState(null)
  const [totalPrice, setTotalPrice] = useState(0)
  const { getCart } = useWooCart()
  const { steps, currentStep } = useAppNavigation()
  const { getValues } = useFormContext()

  const obj = getValues()

  useEffect(() => {
    getCart().then((response) => setCart(response))
    // console.log('useEffect', currentStep, totalPrice)
  }, [])

  useEffect(() => {
    if (wooProducts && currentStep) {
      const wooProduct = wooProducts
        .filter((product) => product.id_product == currentStep.id_product)
        .shift()
      if (wooProduct) setTotalPrice(wooProduct.price)
      // console.log('useEffect', totalPrice, wooProduct)
    }
  }, [currentStep, wooProducts])

  return (
    <>
      {cart?.woocart && (
        <div id="cart_resume">
          <div className="totals">
            {/* {Object.keys(cart.woocart).length > 0 &&
              Object.entries(cart.woocart).map(([index, product]) => {
                const cartData = JSON.parse(
                  product.app_data.MCCRETARITAG.replace(
                    new RegExp('\\\\', 'g'),
                    ''
                  )
                )

                const currentProduct = wooProducts
                  ? wooProducts.find((prod) => prod.id == product.id_product)
                  : null

                totalPrice += product.line_total

                return (
                  <div className="line">
                    {currentProduct?.title} para {cartData.Nombre}
                    <br></br>
                    <strong>
                      {new Intl.NumberFormat('es-ES', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(product.line_total)}
                    </strong>
                    &nbsp; al año
                  </div>
                )
              })} */}

            <h4 className="h4">Precio total</h4>
            <div>
              <strong>
                {new Intl.NumberFormat('es-ES', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(totalPrice)}
              </strong>
              &nbsp; al año
            </div>
          </div>

          {currentStep.id === Object.keys(steps).length - 1 && <NavButtons />}

          {currentStep.id > 1 && (
            <div className="current-person">
              <h4 className="h4">
                Tus datos
                {currentStep.id > Object.keys(steps).length - 2 && (
                  <Link to={`/flow/${steps[2].url}`}></Link>
                )}
              </h4>
              <div>
                <strong>Nombre: </strong>
                {obj.MCCPERSTAG.TOMADOR.Nombre}
              </div>
              <div>
                <strong>Apellidos: </strong>
                {obj.MCCPERSTAG.TOMADOR.Apellido1}
                &nbsp;
                {obj.MCCPERSTAG.TOMADOR.Apellido2}
              </div>
              <div>
                <strong>NIF: </strong>
                {obj.MCCPERSTAG.TOMADOR.Nif}
              </div>
              <div>
                <strong>Email: </strong>
                {obj.MCCPERSTAG.TOMADOR.Email}
              </div>
              <div>
                <strong>Teléfono: </strong>
                {obj.MCCPERSTAG.TOMADOR.TelefonoParticular}
              </div>

              <div>
                <strong>Provincia: </strong>
                {provincias[obj.WOO.billing_state]}
              </div>
              <div>
                <strong>Localidad: </strong>
                {obj.MCCPERSTAG.TOMADOR.Localidad}
              </div>
              <div>
                <strong>Dirección: </strong>
                {obj.MCCPERSTAG.TOMADOR.NombreVia},&nbsp;
                {obj.MCCPERSTAG.TOMADOR.NumVia}
              </div>
              <div>
                <strong>Código postal: </strong>
                {obj.MCCPERSTAG.TOMADOR.CodPostal}
              </div>
            </div>
          )}

          {currentStep.id > 0 && (
            <div className="current-pet">
              <h4 className="h4">
                Datos de tu mascota
                {currentStep.id > 1 && (
                  <Link to={`/flow/${steps[0].url}`}></Link>
                )}
              </h4>
              <div>
                <strong>Nombre: </strong>
                {obj.MCCRETARITAG.Nombre}
              </div>
              <div>
                <strong>Edad: </strong>
                {calculateAge(obj.MCCRETARITAG.FecNacimiento)}
              </div>
              <div>
                <strong>Raza: </strong>
                {obj.MCCRETARITAG.Raza}
              </div>
              <div>
                <strong>Nº de Microchip: </strong>
                {obj.MCCRETARITAG.Microchip}
              </div>
            </div>
          )}

          {Object.keys(cart.woocart).length > 0 && (
            <div>
              <div className="h5">Otras mascotas añadidas</div>
              <ol className="product-line">
                {Object.entries(cart.woocart).map(([index, product]) => {
                  const pet = product.MCCRETARITAG
                  return (
                    <li key={index}>
                      {pet?.Nombre}
                      <strong>
                        {new Intl.NumberFormat('es-ES', {
                          style: 'currency',
                          currency: 'EUR',
                        }).format(product?.line_total)}
                      </strong>
                    </li>
                  )
                })}
              </ol>
            </div>
          )}
        </div>
      )}
    </>
  )
}

function calculateAge(date) {
  if (!date) return

  const inputDate = [
    date.substring(0, 4),
    date.substring(4, 6),
    date.substring(6, 8),
  ]

  var today = new Date()
  var year = today.getFullYear()
  var month = today.getMonth() + 1
  var day = today.getDate()
  var yy = parseInt(inputDate[0])
  var mm = parseInt(inputDate[1])
  var dd = parseInt(inputDate[2])

  var years, months
  // months
  months = month - mm
  if (day < dd) {
    months = months - 1
  }
  // years
  years = year - yy
  if (month * 100 + day < mm * 100 + dd) {
    years = years - 1
    months = months + 12
  }

  return `${years}${years > 1 ? ' años' : ' año'} y ${months}${
    months > 1 ? ' meses' : ' mes'
  }`
}
