import xmljs from 'xml-js'

/*
result = convert.js2xml(js, options);     // to convert javascript object to xml text
result = convert.json2xml(json, options); // to convert json text to xml text
result = convert.xml2js(xml, options);    // to convert xml text to javascript object
result = convert.xml2json(xml, options);  // to convert xml text to json text
*/

export function useXML() {
  // const storage = useStoreCart()
  function jsonToXML(data) {
    // const object = {
    //   // MCCIDENTAG: initialFields.MCCIDENTAG,
    //   MCCRETARITAG: Object.keys(data.MCCTARITAG)
    //     .filter((k) => data.MCCTARITAG[k] != null)
    //     .reduce((a, k) => ({ ...a, [k]: data.MCCTARITAG[k] }), {}),
    //   MCCPERSTAG: {
    //     TOMADOR: Object.keys(data.MCCPERSTAG.TOMADOR)
    //       .filter((k) => data.MCCPERSTAG.TOMADOR[k] != null)
    //       .reduce((a, k) => ({ ...a, [k]: data.MCCPERSTAG.TOMADOR[k] }), {}),
    //   },
    // }

    // <Entrada xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">
    const storage = JSON.parse(window.sessionStorage.getItem('woo_cart'))
    const parseObject = {
      ...data,
      // Variables no definidas y formato
      MCCRETARITAG: {
        ...data?.MCCRETARITAG,
        RefAuxiliar: storage ? storage.ssid : null,
        DetalleGarantias: 'N',
      },
    }
    const options = {
      compact: true,
      ignoreComment: true,
      spaces: 4,
      fullTagEmptyElement: true,
    }
    const resultXML = xmljs.js2xml(parseObject, options)
    return resultXML
  }

  return { jsonToXML }
}
