import React from 'react'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields } from '../../consts'

export default function Nombre({ title, label, required = true }) {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext()

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = () => {
    setStorageData(getValues())
  }

  return (
    <div className="form-field">
      {title && <span>{title}</span>}

      <div className="form-group">
        <label htmlFor="MCCRETARITAG.Nombre">{label || 'Nombre'}</label>
        <input
          className={`form-control${
            errors.MCCRETARITAG?.Nombre ? ' hasError' : ''
          }`}
          type="text"
          id="MCCRETARITAG.Nombre"
          placeholder={label || 'Nombre'}
          {...register('MCCRETARITAG.Nombre', {
            onChange: handleChange,
            required: required === true ? 'El nombre es obligatorio.' : false,
            pattern: {
              value: /^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/i,
              message: 'Has introducido caracteres no permitidos.',
            },
            maxLength: {
              value: 150,
              message: 'El nombre es demasiado largo.',
            },
          })}
        />
        {errors.MCCRETARITAG?.Nombre && (
          <div className="error">{errors.MCCRETARITAG.Nombre.message}</div>
        )}
      </div>
    </div>
  )
}
